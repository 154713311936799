import { navigate } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../styles/globalStyles';
import HomePage from '../templates/homePageTemplate/homePageTemplate';

const RootIndex = ({ location }) => {
  React.useEffect(() => {
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
    if (location?.pathname !== '/') {
      navigate('/404');
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pro2Go | Self-Serve Propane Kiosks</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          name="description"
          content="In less than a minute you can complete your transaction and have a full propane cylinder in hand without ever having to wait for an associate or step foot inside the store."
        ></meta>
        <meta name="generator" content="WordPress 5.3.2"></meta>
      </Helmet>
      <HomePage />
    </>
  );
};

export default RootIndex;
