import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const Container = styled.div`
  width: 580px;
    max-width: 100%;
    text-align: left;
    margin: 100px auto;
    @media(max-width: 991px) {
        width: 100%;
        margin: 30px auto;
        padding: 0 30px;
    }
    @media(max-width: 767px) {
        width: 100%;
        justify-content: center;
        margin: 25px auto;
    }
`;

export const HeaderH1 = styled.h1 `
    font-family: 'Dosis', sans-serif;
    font-weight: 700;
    color: #4e4e4e;
    font-size: 1.75em;
    line-height: 1em;
    text-transform: uppercase;
    color: #263b91;
    font-size: 2em;

`;

export const HeaderH6 = styled.h6`
    font-family: 'Titillium Web', sans-serif;
    font-weight: 700;
    color: #4e4e4e;
    font-size: 1.1em;
    line-height: 1.3em;
`;

export const Paragraph = styled.p`
    font-family: 'Titillium Web', sans-serif;
    font-weight: 300;
    color: #000000;
    font-size: 1.1em;
    line-height: 1.3em;
    font-style: normal;
`;

export const PaymentMethodContainer = styled(Row)`
    margin-bottom : 20px;
    @media (max-width: 767px){
        flex-direction: column;
    }
`;

export const PaymentMethod = styled(Col)`
    float: left;
    min-height: 1px;
    padding: 0 15px;
    position: relative;
`;

export const PaymentCards = styled(Col)`
    min-height: 1px;
    padding: 0 15px;
    position: relative;
`;

export const PaymentHeaderH6 = styled.h6`
    font-family: 'Titillium Web', sans-serif;
    font-weight: 700;
    color: #4e4e4e;
    font-size: 1.1em;
    line-height: 1.3em;
`;

export const UnorderedList = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media(max-width: 767px) {
        justify-content: space-around;
    }
`;

export const ListItem = styled.div`
    padding: 0 10px;
`;

export const ParagraphSmall = styled.p`
 font-size: 0.8em;
`;

export const PaymentCardsImg = styled.img`

`
export const Hr = styled.hr`
    border: 1px solid #c1c6cb;
    clear: both;
    margin: 22px 0 21px;
    height: 0;
`;

export const HighlightBox = styled.div`
    background-color: rgba(0,0,0,0.1);
    border: 0;
    border-bottom: 1px solid #FFFFFF;   
    border-radius: 6px;
    box-shadow: inset 0 6px 6px rgb(0 0 0 / 30%);
    margin: 0 0 12px 0;
    padding: 20px;
    width: 73%;
    @media(max-width: 768px) {
        width: auto;
    }
`;

export const HeaderH3 = styled.h3`
    font-family: 'Dosis', sans-serif;
    font-weight: 700;
    color: #263b91;
    font-size: 1.5em;
    line-height: 1em;
    margin-bottom: 0.25em;
`;

export const LocationCol1 = styled(Col)`
    width: 50%;
    flex:2;
    @media(max-width: 767px){
        width: 100%
    }
`;

export const LocationCol2 = styled(Col)`
    width: 30%;
    @media(max-width: 767px){
        width: 100%
    }
    img{
        @media (max-width: 767px){
            max-width: 66% !important;
            left: 64px;
        }
    }
`;
