import * as React from 'react';
import HelpList from '../../components/helpList/helpList';
import * as Styled from './homePageTemplateStyle';
import BootstrapNavbar from '../../components/navgationBar/navigationBar';
import SelfServiceHome from '../../components/selfServiceHome/selfServiceHome';
import Footer from '../../components/footer/footer';

const HomePage = () => {
  return (
    <Styled.HomePageContainer>
      <BootstrapNavbar />
      <SelfServiceHome />
      <Footer />
    </Styled.HomePageContainer>
  );
};

export default HomePage;
