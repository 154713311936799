import React from 'react';
import  * as Styled from './selfServiceHomeStyle';
import interac from '../../images/interac.png';
import mastercard from '../../images/mastercard.png';
import visa from '../../images/visa.png';
import LocationImg from '../../images/icon_location-big.png';
import { Header, ParaText } from '../../styles';
const SelfServiceHome = () => {
    return (
        <Styled.Container>
            <Styled.HeaderH1>Self-Serve propane kiosks</Styled.HeaderH1>
            <Styled.HeaderH6>Customers can purchase or exchange BBQ cylinders 24/7 without the need of an attendant.</Styled.HeaderH6>
            <Styled.Paragraph>In less than a minute you can complete your transaction and have a full propane cylinder in hand without ever having to wait for an associate or step foot inside the store.</Styled.Paragraph>
            <Styled.Hr/>
            <Styled.PaymentMethodContainer>
                <Styled.PaymentMethod>
                    <Styled.PaymentHeaderH6>ACCEPTED METHODS OF PAYMENT</Styled.PaymentHeaderH6>
                    <Styled.ParagraphSmall>*Interac at select locations</Styled.ParagraphSmall>
                </Styled.PaymentMethod>
                <Styled.PaymentCards>
                    <Styled.UnorderedList>
                        <Styled.ListItem><img src={visa} alt="Visa" /></Styled.ListItem>
                        <Styled.ListItem><img src={mastercard} alt="MasterCard" /></Styled.ListItem>
                        <Styled.ListItem><img src={interac} alt="Interac" /></Styled.ListItem>
                    </Styled.UnorderedList>
                </Styled.PaymentCards>
            </Styled.PaymentMethodContainer>
            <Styled.Hr/>
            <Header>About</Header>
            <Styled.Paragraph>Customers can purchase or exchange BBQ cylinders 24/7 without the need of an attendant.</Styled.Paragraph>          
            <Styled.Paragraph>Traditional cages often require you to wait anywhere from 10-15 minutes or more. With PRO2GO, in less than a minute you can complete your transaction and have a full propane cylinder in hand without ever having to wait for an associate or step foot inside the store.</Styled.Paragraph>            
            <Styled.Paragraph>PRO2GO’s intuitive process is quick and customers exchanging or purchasing for the first time can follow the 3 easy steps listed on all our kiosks.</Styled.Paragraph>            
            <Styled.Paragraph>All PRO2GO cylinders are thoroughly inspected and maintained to the highest safety regulations in the industry.</Styled.Paragraph>            
            <Styled.Paragraph>And with our assistance hotline, you can be assured that our expert customer service representatives will be ready to inform and help you every step of the way.</Styled.Paragraph>
            <Styled.HeaderH3>About PRO2GO:</Styled.HeaderH3>
            <Styled.Paragraph>PRO2GO is a unique alternative to traditional BBQ cylinder exchange cages and fill centers. PRO2GO was launched in 2009 and has since grown to over 290 locations across Canada in select Walmart locations. We are delighted to offer you the fastest BBQ cylinder exchange service in Canada!</Styled.Paragraph>             
        </Styled.Container>
    );
}


export default SelfServiceHome;
